import {observer} from 'mobx-react'
import React from 'react'
import {Icon, ListItem} from 'react-native-elements'
import {addAccountStore} from '../../../../Components/Modal/AddAccountModal/Store'
import {persistedStore} from '../../../../Mobx'
import i18n from '../../../../i18n'
import {dashboardStore} from '../../Dashboard/Store'
import {userMenuStore} from './Store'
interface IProps {}

const UserListItem: React.FC<IProps> = ({children}) => {
  const {User} = dashboardStore
  const {ChildUsers, MainUser} = persistedStore

  const isChildUser = MainUser?.MainUserGuid !== User?.MainUserGuid

  if (User === undefined) {
    return null
  }

  const hasChildUsers = ChildUsers && ChildUsers.length > 0

  const renderAddAccount = () => {
    if (isChildUser) {
      return null
    }

    return (
      <ListItem
        onPress={() => {
          dashboardStore.ToggleUserMenu(false)
          addAccountStore.ToggleModal()
        }}>
        <Icon name="user-plus" type="font-awesome-5" />
        <ListItem.Content>
          <ListItem.Title>{i18n.t('UserMenu.Account.Add')}</ListItem.Title>
          <ListItem.Subtitle>
            {i18n.t('UserMenu.Account.AddSubText')}
          </ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>
    )
  }
  const renderChildMenuItems = () => {
    if (!hasChildUsers) {
      return null
    }

    return (
      <ListItem
        onPress={() => {
          dashboardStore.ToggleUserMenu(false)
          userMenuStore.ToggleChildMenu()
        }}>
        <Icon name="users" type="font-awesome-5" />
        <ListItem.Content>
          <ListItem.Title>{i18n.t('UserMenu.Account.Switch')}</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
    )
  }

  return (
    <>
      {renderChildMenuItems()}
      {renderAddAccount()}
    </>
  )
}

export default observer(UserListItem)
