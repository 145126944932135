import {makeAutoObservable, runInAction} from 'mobx'
import {navigate} from '../../../Helpers/NavigationHelper'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {GetI18NAlertMessage} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {IAlertMessage, MyErrorMessage, ToprError} from '../../../Models/Client'
import RegisterController from '../../../Services/Topr.Core/RegisterController'
import UserController from '../../../Services/Topr.Core/UserController'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {persistedStore} from '../../../Mobx'

export default class Store {
  public IsSubmitting = false
  public IsConfirming = false

  public ResultMessage?: IAlertMessage = undefined
  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.IsSubmitting = false
    this.IsSubmitting = false
    this.ResultMessage = undefined
  }

  public ChangeEmail(email: string, onError: (errorMessage: string) => void) {
    DoServiceCall(() => {
      this.IsSubmitting = true

      RegisterController.EmailExists(email)
        .then(() => {
          //start change email

          UserController.StartChangeEmail(email)
            .then(() => {
              navigate('changeEmailConfirmationSent', {email})
              runInAction(() => {
                this.IsSubmitting = false
              })
            })
            .catch((toprError: ToprError) => {
              SetFriendlyMessage(toprError, onError, [
                new MyErrorMessage(
                  'USER_EMAIL_NOT_UNIQUE',
                  'ChangeEmail.Form.ServerErrors.Title',
                  'ChangeEmail.Form.ServerErrors.USER_EMAIL_NOT_UNIQUE',
                  'warning',
                  {
                    email,
                  },
                ),
              ])

              runInAction(() => {
                this.IsSubmitting = false
              })
            })
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, onError, [
            new MyErrorMessage(
              'USER_EMAIL_NOT_UNIQUE',
              'ChangeEmail.Form.ServerErrors.Title',
              'ChangeEmail.Form.ServerErrors.USER_EMAIL_NOT_UNIQUE',
              'warning',
              {
                email,
              },
            ),
          ])

          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }

  public ConfirmChangeEmail(requestIdentifier: string) {
    DoServiceCall(() => {
      this.IsConfirming = true

      const {UserToken} = persistedStore

      UserController.ConfirmChangeEmail(requestIdentifier)
        .then(() => {
          //
          appUiStore.ShowToast({message: i18n.t('ChangeEmail.Form.Succes')})

          if (UserToken === undefined) {
            navigate('login')
          } else {
            dashboardStore.GetUser()
            navigate('dashboard')
          }

          runInAction(() => {
            this.IsConfirming = false
          })
        })
        .catch((toprError: ToprError) => {
          console.log('ConfirmChangeEmail error')
          runInAction(() => {
            this.IsConfirming = false
            this.ResultMessage = GetI18NAlertMessage(
              'ChangeEmail.Form.ServerErrors',
              toprError.Code,
            )
          })
        })
    })
  }
}
export const changeEmailStore = new Store()
