import {AxiosError} from 'axios'
import i18n from '../i18n'
import {appUiStore} from '../Mobx/AppUiStore'
import {MyErrorMessage} from '../Models/Client'
import ErrorCodes from '../Models/Client/ErrorHandling/ErrorCodes'
import ToprError from '../Models/Client/ErrorHandling/ToprError'
import {myLog} from '../Services/Client/LogService'

/**
 * Check the Network connection first beforde doing a Service Call
 */
export const DoServiceCall = (serviceCall: () => void) => {
  if (appUiStore.IsConnected) {
    serviceCall()
  } else {
    appUiStore.ShowToast({message: i18n.t('Common.ServerErrors.NO_CONNECTION')})
  }
}

export const HandleAxiosCatch = (
  error: AxiosError,
  reject: (toprError: ToprError) => void,
) => {
  //console.log('handleAxiosCatch', error.config.url, error.response?.data)
  if (error && error.message === 'Network Error') {
    appUiStore.ShowToast({message: i18n.t('Common.ServerErrors.NO_CONNECTION')})
    reject(new ToprError(ErrorCodes.NO_CONNECTION))
  } else if (
    error.response &&
    error.response.status === 401 &&
    error.response.data === ''
  ) {
    appUiStore.Logout()
    appUiStore.ShowToast({message: i18n.t('Login.LoginExpired')})
    reject(new ToprError(ErrorCodes.NOT_AUTHENTICATED))
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.Code
  ) {
    console.log('sd', error.response.data)
    switch (error.response.data.Code) {
      case ErrorCodes.TECHNICAL_EXCEPTION:
      case ErrorCodes.EXCEPTION:
        //case ErrorCodes.API_METHOD_NOT_FOUND:
        appUiStore.ShowToast({
          message: i18n.t('Common.ServerErrors.EXCEPTION', {
            code: error.response.data.Code,
          }),
        })
        myLog.error(error.response.config.url, error.response.data.Code)
        reject(new ToprError(ErrorCodes.EXCEPTION))
        break
      default:
        myLog.error(error.response.config.url, error.response.data.Code)
        //ShowToast(error.response.data.Code)
        reject(new ToprError(error.response.data.Code))
        break
    }
  } else {
    //An error occured on the server which doesn't return { Code:string, Message:String}
    appUiStore.ShowToast({
      message: i18n.t('Common.ServerErrors.EXCEPTION', {
        code: ErrorCodes.UNHANDLED_SERVER_ERROR,
      }),
    })
    myLog.error(error?.response?.config.url, 'UNHANDLED_SERVER_ERROR')
    reject(new ToprError(ErrorCodes.UNHANDLED_SERVER_ERROR))
  }
}

export const HandleInziiCatch = (
  error: AxiosError,
  reject: (toprError: ToprError) => void,
) => {
  //console.log('handleAxiosCatch', error.config.url, error.response?.data)
  if (error && error.message === 'Network Error') {
    appUiStore.ShowToast({message: i18n.t('Common.ServerErrors.NO_CONNECTION')})
    reject(new ToprError(ErrorCodes.NO_CONNECTION))
  } else {
    //An error occured on the server which doesn't return { Code:string, Message:String}
    appUiStore.ShowToast({
      message: i18n.t('Common.ServerErrors.EXCEPTION', {
        code: ErrorCodes.UNHANDLED_INZZII_ERROR,
      }),
    })
    console.log(error?.response?.status)
    myLog.error(error?.response?.config.url, 'UNHANDLED_INZZII_ERROR')
    reject(new ToprError(ErrorCodes.UNHANDLED_INZZII_ERROR))
  }
}

export const Geti18NMessage = (
  toprError: ToprError,
  errorMessages?: MyErrorMessage[],
) => {
  if (errorMessages) {
    const foundError = errorMessages.find(
      (customError) => customError.Code === toprError.Code,
    )
    if (foundError) {
      return i18n.t(foundError.I18MessageKey, foundError.Values)
    }
  }

  return `[${toprError.Code}]`
}

export const SetFriendlyMessage = (
  toprError: ToprError,
  onError: (errorMessage: string) => void,
  errorMessages?: MyErrorMessage[],
) => {
  const i18nMessage = Geti18NMessage(toprError, errorMessages)
  onError(i18nMessage)

  return

  // //show unhandled code to dev
  // switch (toprError.Code) {
  //   case ErrorCodes.TECHNICAL_EXCEPTION:
  //   case ErrorCodes.EXCEPTION:
  //   default:
  //     onError(`[${toprError.Code}]`)
  //     break
  // }
  // return
}

export const Json2Url = (obj: any, prefix?: string) => {
  let str = []
  let p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + '[' + p + ']' : p
      let v = obj[p]
      const result: string =
        v !== null && typeof v === 'object'
          ? Json2Url(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      str.push(result)
    }
  }
  return str.join('&')
}
