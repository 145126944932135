import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {myLog} from '../../../Services/Client/LogService'
import I18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {ToprError} from '../../../Models/Client'
import UserController from '../../../Services/Topr.Core/UserController'
import AuthenticationController from '../../../Services/Topr.Core/AuthenticationController'
import {dashboardStore} from '../../../Screens/Dashboard/Dashboard/Store'

export default class Store {
  public IsSubmitting = false
  public IsVisible = false

  constructor() {
    makeAutoObservable(this)
  }

  public ToggleModal() {
    this.IsVisible = !this.IsVisible
  }

  public AddAccount(name: string) {
    this.IsSubmitting = true

    UserController.AddChildUser(name)
      .then((userGuid) => {
        runInAction(() => {
          this.SwitchToNewUser(userGuid)
          this.ToggleModal()
          this.IsSubmitting = false
        })
      })
      .catch((toprError: ToprError) => {
        runInAction(() => {
          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowToast({message})
          })

          this.ToggleModal()
          this.IsSubmitting = false
        })
      })
  }

  private SwitchToNewUser(userGuid: string) {
    AuthenticationController.SwitchUser(userGuid)
      .then(() => {
        //UserController.GetChildUsers()
        dashboardStore.Initialize()
        //navigate('dashboard')
      })
      .catch((toprError: ToprError) => {
        runInAction(() => {
          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowToast({message})
          })
        })
      })
  }
}
export const addAccountStore = new Store()
