export default {
  Account: {
    Header: 'Wallet',
    ChangeName: 'Change name',
    ChangeEmail: 'Change email',
    ChangePassword: 'Change password',
    SetProfilePicture: 'Set profile picture',
    PictureModal: {TakePicture: 'Foto nemen', ChoosePicture: 'Foto kiezen'},
  },
  AutomaticCharge: {
    Buttons: {
      Create: 'Enable automatic top up',
      EditAmount: 'Change amount',
      EditBank: 'Change bank info',
      TurnOff: 'Turn off',
    },
    Form: {
      Action: 'Enable Automatic top up',
      Title: 'Automatic top up for {{currencyName}}',
      AuthenticateAction: 'To enable automatic top up.',
      ConfirmTurnOff:
        'Are you sure you want to turn off automatic top up for {{currencyName}}?',
      CreateMessage: {
        Title: 'Enabling automatic top up',
        Message:
          'It can take a couple of minutes before this setting is enabled.',
      },
      TurnOffMessage: {
        Title: 'Automatic top up',
        Message: 'Automatic top up has been turned off.',
      },
      EditMessage: {
        Title: 'Automatic top up',
        Message: 'The changes are saved.',
      },
      ChargeCostsMessage: 'The cost to automatic top up are',
      Fields: {
        Amount: {
          Label: 'Topup amount',
        },
        WeeksAmount: {
          Label: 'Topup steps each week',
        },
        Initials: {
          Label: 'Initials',
        },
        Surname: {
          Label: 'Surname',
          Errors: {
            Required: 'Enter your surname.',
          },
        },
        Email: {
          Label: 'Email address',
          Errors: {
            Required: 'Enter your email address.',
          },
        },
        Phone: {
          Label: 'PhoneNumber',
          Errors: {
            Required: 'Enter your phonenumber.',
          },
        },
        City: {
          Label: 'City',
          Errors: {
            Required: 'Enter your city.',
          },
        },
        Street: {
          Label: 'Street name',
          Errors: {
            Required: 'Enter your street name.',
          },
        },
        StreetNumber: {
          Label: 'Street number',
          Errors: {
            Required: 'Enter your street number.',
          },
        },
        Zipcode: {
          Label: 'Zipcode',
          Errors: {
            Required: 'Enter your zipcode.',
          },
        },
        SelectedBank: {
          Label: 'Bank',
          EmptyText: '<Select a bank>',
          Errors: {
            Required: 'Select a bank.',
          },
        },
      },
      EMandateResults: {
        Title: 'Automatic top up',
        EMANDATE_SUCCESS: 'Enabling Automatic top up is successfull.',
        EMANDATE_CANCELLED: 'Enabling Automatic top up was cancelled.',
        EMANDATE_EXPIRED:
          'Enabling Automatic top up has expired (EMANDATE_EXPIRED).',
        EMANDATE_FAILURE:
          'Enabling Automatic top up has failed (EMANDATE_FAILURE).',
        EMANDATE_PENDING: "Setting up Automatic top up is 'Pending'.",
        EMANDATE_UNKNOWN_STATUS:
          'An unknown error occured while enabling Automatic top up (EMANDATE_UNKNOWN_STATUS)',
      },
    },
    LoadingFailed: 'Get Wallets failed',
  },
  CancelAccount: {
    Confirm: 'Are you sure you wish to cancel this account({{name}})?',
    Header: 'Cancel account',
    Info: 'Here you can cancel {{name}}. The remaining balance will be transfered to the main account {{parentName}}.\n\nCards are blocked and then linked to the main account {{parentName}}. Je kunt de kaarten weer gebruiken door ze te deblokkeren.',
    Succes: 'Account {{name}} has been cancelled',
  },
  AddAccountModal: {
    Form: {
      Action: 'Add',
      NameField: {
        Label: 'Name',
        Errors: {
          Required: 'Please enter a name',
        },
      },
      ServerErrors: {
        Title: 'Failed to add account',
      },
    },
  },
  AddToken: {
    Form: {
      Fields: {
        ActivationCode: {
          Label: 'Activation code',
          Errors: {
            Required: 'Please enter your activation code.',
          },
        },
      },
      ServerErrors: {
        Title: 'Failed to activate card',
        TOKEN_NOT_FOUND: 'No card with this activation code has been found.',
        TOKEN_ALREADY_ACTIVATED: 'This card has already been activated.',
      },
      Succes:
        'The card with activation code {{activationCode}} has been added to your account.',
    },
    Header: 'Add card',
    Info: 'Enter your activation code',
  },
  AddTokenUid: {
    ScanAgain: 'Please Scan again',
    CardScanned: 'Card scannned.',
    Form: {
      Uid: {
        Label: 'Scan your card with an NFC reader.',
        Errors: {
          Invalid: 'This is an invalid card.',
          NotFound: 'Kaart niet gevonden',
        },
      },

      ServerErrors: {
        Title: 'Failed to activate card',
        TOKEN_NOT_FOUND: 'No card has been found.',
        TOKEN_ALREADY_ACTIVATED: 'This card has already been activatied.',
      },
      Succes: 'This card has been added to your account.',
    },
    Header: 'Add card',
  },
  AuthenticateAction: {
    Title: 'Authenticate',
    Pin: 'Enter your pin code to {{message}}',
    FingerPrint: 'Scan your fingerprint {{message}}',
  },
  ChangeName: {
    Header: 'Change name',
    Form: {
      Action: 'Change name',
      NameField: {
        Label: 'Name',
        Errors: {
          Required: 'Enter a name.',
        },
      },
      ServerErrors: {
        Title: 'Failed to change name',
      },
      Succes: 'Name has been changed.',
    },
  },
  ChangeEmail: {
    ConfirmationSentStep: {
      Header: 'Conformation email has been sent',
      TextPart1:
        'A email with the conformation link has been sent to the new email address ',
      TextPart2:
        '\n\n Click on the conformation link in the email to complete the email change.\n\n If you did not receive the email please check your spam folder.',
    },
    Form: {
      Action: 'Change email',
      EmailField: {
        Label: 'Email address',
        Errors: {
          Required: 'Enter an email address.',
          Regex: 'enter a valid email address.',
        },
      },
      ServerErrors: {
        Title: 'Failed to change email address',
        REQUESTIDENTIFIER_NOT_FOUND:
          'The request to change your email was not found.',
        REQUESTIDENTIFIER_EXPIRED:
          'The request to change your email has expired.',
        REQUESTIDENTIFIER_REVOKED:
          'The request to change your email has been revoked.',
        USER_EMAIL_NOT_UNIQUE: 'This email address is already in use.',
      },
      Succes: 'Email address has been changed .',
    },
    Header: 'Change email address',
  },
  ChangePassword: {
    Header: 'Change password',
    Form: {
      Action: 'Change password',
      Fields: {
        OldPassword: {
          Label: 'Old password',
          Errors: {
            Required: 'Enter your old password.',
            Invalid: 'Old password incorrect.',
          },
        },
        Password: {
          Label: 'Password',
          Errors: {
            Required: 'Enter new password.',
            MinLength: 'The password has to be at least 6 characters long.',
          },
        },
        ConfirmPassword: {
          Label: 'Control password',
          Errors: {
            Required: 'Enter your control password.',
            Equality: 'The control password is not the same as the password',
          },
        },
      },
      ServerErrors: {
        Title: 'Failed to change the password',
      },
      Succes: 'Password has been changed.',
    },
  },
  ChangeProfilePicture: {
    TakePictureHeader: 'Take photo',
    ChoosePictureHeader: 'Choose photo',
    Form: {
      Again: 'again',
      Action: 'Save',
    },
    Permissions: {
      Camera: 'The app requires access to the camera to take photos.',
      Library: 'The app requires access to the film roll to select a photo.',
    },
  },
  Charge: {
    Form: {
      Action: 'Top up',
      AuthenticateAction: '{{amount}} to top up.',
      Success: {
        Title: 'Balance has been added ',
        Message:
          'It can take a couple of minutes for the balance to be updated.',
      },
      ChargeCostsMessage: 'The cost to top up are',
      Fields: {
        Amount: {
          Label: 'Amount',
          Errors: {
            Required: 'Enter an amount.',
            //MinMax: 'Enter an amount between {{min}} and {{max}}',
            Min: 'Enter an amount of at least {{min}}',
            Max: 'Enter an amount up to {{max}}',
          },
        },
        AmountHb: {
          Label: 'Token amount',
          Errors: {
            Required: 'Select the token amount.',
          },
        },
        ChargeMethod: {
          Label: 'Method',
          Errors: {
            NotAvailable: 'There are no top-up methods available',
          },
        },
      },
      ServerErrors: {
        Title: 'Failed to top up',
        CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED:
          'This payment method is not supported.',
        PROCESS_FAILED: 'Process failed.',
        PAYMENT_FAILED: 'Payment failed.',
        PAYMENT_EXPIRED: 'Payment expired.',
        PAYMENT_CANCELED: 'Payment cancelled.',
        WAITING_FOR_PAYMENT: 'waiting for payment.',
        UNKNOWN: 'an unknown error has occurred.',
        Refunded: 'Payment refunded.',
        ProcessFailed: 'Porcess failed.',
        PaymentFailed: 'Payment failed.',
        PaymentExpired: 'Payment expired.',
        PaymentCanceled: 'Payment canceled.',
      },
    },
    Header: 'Top up',
    IntroText0: 'Choose a top up method and enter the amount with which you',
    IntroText1: ' Want to top up.',
    MaxBalance0: 'You can ',
    MaxBalance1: 'Top up.',
    Method: 'Method',
  },
  CodePush: {
    UpdateDialog: {
      Title: 'Update',
      OptionalUpdateMessage:
        'An update is available. Do you want to install it now?',
      OptionalInstallButtonLabel: 'Install',
      OptionalIgnoreButtonLabel: 'Ignore',
      MandatoryUpdateMessage:
        'An important update is available, this will now be installed',
      MandatoryContinueButtonLabel: 'Verder',
    },
    UpdateTitle: 'Topr Wallet is being updated.',
    DownloadingPackage: 'Downloading update {{procent}}%.',
    InstallingUpdate: 'Installing update.',
  },
  Common: {
    Account: 'Wallet',
    Accounts: 'Wallets',
    Add: 'Add',
    Back: 'Back',
    BackToDashboard: 'Back to dashboard',
    Cancel: 'Cancell',
    Close: 'Close',
    Card: 'Card',
    Cards: 'Cards',
    Check: 'Check',
    Continue: 'Continue',
    Confirm: 'Confirm',
    Coupons: 'Vouchers',
    Date: 'Date',
    Dashboard: 'Dashboard',
    DefaultErrorMessage: 'Unfortunately something went wrong. [{{code}}]',
    Delete: 'Delete',
    Description: 'Description',
    Details: 'Details',
    DropImage: 'Click here to upload an image.',
    Edit: 'Edit',
    End: 'End',
    Help: 'Help',
    Off: 'Off',
    Ok: 'Ok',
    On: 'On',
    ToOrder: 'Order',
    Order: 'Order',
    Orders: 'Orders',
    Pay: 'Pay',
    QrCode: 'QR code',
    Save: 'Save',
    SavingCards: 'Saving cards',
    Scanner: 'Scanner',
    Stamps: 'Stamps',
    Valid: 'Vaild',
    ValidAlways: 'This coupon has permanent validity.',
    ValidFrom: 'Valid from:',
    ValidUntill: 'Up to and including:',
    Location: 'Location',
    Menu: 'Menu',
    No: 'No',
    Received: 'Received',
    Redeem: 'Redeem',
    Redeemed: 'Redeemed',
    Search: 'Search',
    ServerErrors: {
      Title: 'Wrong',
      NO_CONNECTION: 'No internet connection .',
      EXCEPTION: 'Unfortunately something went wrong.\n [{{code}}]',
    },
    Stamp: 'Stamp',
    Start: 'Start',
    Status: 'Status',
    Summary: 'Summary',
    Token: 'Token',
    Tokens: 'Tokens',
    Total: 'Total',
    Trips: 'Trips',
    Wallet: 'Wallet',
    Wallets: 'Wallets',
    Yes: 'Yes',
  },
  CouponsSummary: {
    Header: 'Coupon summary',
    NotRedeemed: 'Not Redeemed',
    Redeemed: 'Redeemed',
  },
  Dashboard: {
    AddToken: 'Add token',
    AddLocation: 'Add location',
    Tabs: {
      Payment: 'Payment',
      Coupons: 'COUPONS',
      Savings: 'Savings',
      Subscriptions: 'Subscriptions',
      Tickets: 'Tickets',
    },
    NoCoupons: 'You don’t have coupons yet.',
    NoSavingCards: 'You don’t have saving cards yet.',
  },
  Enums: {
    AutomaticChargeStatusTypesDTO: {
      Off: 'Disabled',
      Pending: 'Request waiting for verification',
      Denied: 'Request denied',
      Blocked: 'Blocked',
      EnabledWithVerification: 'Active',
      EnabledWithoutVerification: 'Active',
      Initial: 'Not set',
    },
  },
  GeneralMenu: {
    CouponSummary: 'Coupon Summary',
    SavingCardSummary: 'Saving card summary',
  },
  ForgotPassword: {
    Header: 'Reset password',
    Info: 'Enter your email and you will receive an email to change your password.',
    Form: {
      Action: 'Sent email',
      EmailField: {
        Label: 'Email address',
        Errors: {
          Required: 'Enter your email address.',
        },
      },
      ServerErrors: {
        Title: 'Failed to reset password',
      },
      Succes: {
        Message:
          'An email has been sent to {{email}} with the link to reset your password.',
        Title: 'Email sent',
      },
    },
  },
  Locations: {
    Header: 'Locations',
    NoLocations: 'There are no {{tenantName}} in your area.',
    RequestAccess: {
      AllowLocationPermission: 'Allow location access',
      Title: 'Location access',
      Message:
        'Topr Wallet needs access to your location to determine which locations are near you where you can use Topr Wallet.',
      OkButton: 'Allow',
      NoButton: 'Refuse',
      SettingsMessageAndroid:
        'Press the button "Allow location access" > Permissions > Location.',
      SettingsMessageIos:
        'Press the button "Allow location access" > Privacy > Location permissons > Topr Wallet > \'When using app\'.',
    },
  },
  LocationDetails: {
    Tabs: {
      Payment: 'Pay',
      Location: 'Location',
    },
    AddLocation: {
      Action: 'Add location',
      Confirm: 'Are you sure you want to add this location?',
      Success: 'Location {{locationName}} added to your wallet.',
    },
    Address: 'Address',
    City: 'City',
    Route: 'Route',
    Street: 'Street',
  },
  Login: {
    CreateAccount: 'Create account',
    ForgotPassword: 'Forgot password?',
    Form: {
      Action: 'Sign in',
      Fields: {
        Email: {
          Label: 'Email address',
          Errors: {
            Required: 'Enter your email address.',
          },
        },
        Password: {
          Label: 'Password',
          Errors: {
            Required: 'Enter your password.',
          },
        },
      },
      Header: 'Sign in',
      ServerErrors: {
        Title: 'Failed to sign in',
        USER_PASSWORD_INVALID: 'Email or password is incorrect.',
        USER_LOCKED_SHORT:
          'Your account has temporarily been blocked. Try again in 15 minutes.',
        USER_LOCKED_LONG:
          'Your account has temporarily been blocked. Try again in 1 hour.',
        USER_BLOCKED:
          'Your account has been blocked permanently. Contact customer support to unblock your account.',
        USER_NOT_FOUND: 'No account has been found with this email/password.',
      },
    },
    Header: 'Sign in',
    LoginExpired: 'your sign in has expired, please try again',
  },
  Loyalty: {
    Status: {
      Active: 'Active',
      Expired: 'Expired',
      NotActive: 'Not active',
      Redeemed: 'Redeemed',
      Completed: 'Completed',
    },
  },
  OrderOnline: {
    Add: 'Add',
    Cart: 'Cart',
    Pay: 'Pay',
    Checkout: 'Checkout',
    CondimentArticleGroup: 'Extra',
    NoArticles: 'No products found.',
    Max: 'Max: {{max}}',
    Min: 'Min: {{min}} ',
    MaxErrorMessage: 'You can’t select more than {{max}} {{type}}.',
    MinErrorMessage: 'Select at least {{min}} {{type}} .',
    OptionalChoice: 'Optional choice - ',
    PaymentMethods: {
      PayOnDelivery: 'Pay on delivery',
      ToprWallet: 'Pay with Topr wallet',
    },
    PaymentForm: {
      AuthenticateAction: '{{amount}} to pay.',
      Fields: {
        SelectedCurrency: {
          Label: 'Payment method',
          Errors: {
            NotAvailable: 'There are no Payment methods available.',
          },
        },
      },
      InsufficientBalance: 'Balance too low.',
      PaymentOrderDescriptionTable: 'Order table {{tableNumber}}',
      ServerErrors: {
        Title: 'Order failed',
        UNKNOWN: 'An unknown error has occurred.',
        INVALID_DELIVERY_TYPE: 'Unknown delivery type ({{deliveryType}})',
        INVALID_GATEWAY: 'Invalid payment method ({{gateway}})',
      },
      Success: {
        Title: 'Order placed',
        Message:
          'The order has been successfully completed and you order with order number ({{orderNumber}}) has been placed.',
      },
    },
    RequiredChoice: 'Required choice',
    SearchProduct: 'Search for product',
    CheckoutForm: {
      Success: {
        Title: 'Order placed',
        Message: 'Order with order number ({{orderNumber}}) has been placed.',
      },
      Fields: {
        Remarks: {
          Label: 'Notes (optional)',
        },
        PaymentMethod: {
          Label: 'Payment method',
          Errors: {
            NotAvailable: 'No payment methods available.',
          },
        },
      },
      ServerErrors: {
        Title: 'order failed',
        UNKNOWN: 'An unknown error has occurred.',
        INVALID_DELIVERY_TYPE: 'invalid delivery method ({{deliveryType}})',
        INVALID_GATEWAY: 'Invalid payment method ({{fateway}})',
      },
    },
  },
  Pincode: {
    Form: {
      Action: 'Set pin code',
      Fields: {
        Pincode: {
          Label: 'Pin code',
          Errors: {
            Required: 'Enter pin code.',
            MinLength: 'The pin code has to be 4 numbers ling.',
            Regex:
              'The pin code has to be 4 numbers long and can only contain numbers.',
          },
        },
        ConfirmPincode: {
          Label: 'Confirm pin code',
          Errors: {
            Required: 'Confirm pin code.',
            MinLength: 'The pin code has to be 4 numbers long.',
            Regex:
              'The pin code has to be 4 numbers long and can only contain numbers.',
            Equality:
              'The conformation pin code has to be same as the pin code.',
          },
        },
      },
      Header: 'Set pin code',
    },
    InvalidMessage: 'Pin code incorrect',
  },
  PlacedOrders: {
    Header: 'Orders',
    NoOrders: 'You have no orders.',
    Table: 'Tafel',
  },
  PlacedOrderDetails: {
    Receipt: 'Receipt',
    Date: 'Datum:',
    OrderNumber: 'Order number:',
    OrderId: 'Order Id:',
  },
  QrCode: {
    Error: {
      Title: 'QR-code wrong',
      InvalidClientDate: 'Invalid QR-code, check the date/time of your phone.',
    },
  },
  QrCodeScanner: {
    BleFailed:
      '(BLE_FAILED)\n We could not confirm if you are close by, please try again in 10 seconds.',
    BranchOfficeNotFound: 'No location found for this QR-code ({{vsamGuid}})',
    CameraPermission:
      'The app need access the phone’s camera to scan a QR-code.',
    NoBleNearby: 'Unfortunately you are not close to a location.',
    InvalidQrCode: 'Invalid QR-code',
    ScanAgain: 'Scan again',
    ScanningLocation: 'Scanning location...',
  },
  Register: {
    Header: 'Set up account',
    Form: {
      Action: 'Set up account',
      ServerErrors: {
        Title: 'Failed to set up account',
        USER_EMAIL_NOT_UNIQUE:
          'This email has been linked with an existing account.',
      },
    },
    NameStep: {
      Header: 'Hello, what is your name?',
      Info: 'Enter your full name.',
      Field: {
        Label: 'Name',
        Errors: {
          Required: 'Enter your name.',
        },
      },
    },
    EmailStep: {
      Header: 'nice to meet you {{name}}!',
      Info: 'What is your email address?',
      Field: {
        Label: 'Email address',
        Errors: {
          Required: 'Enter your email address.',
          Regex: 'Please enter a valid email address.',
        },
      },
    },
    PasswordStep: {
      Header: 'Choose a password',
      Info: 'The password has to be at least 6 characters long',
      Field: {
        Label: 'Password',
        Errors: {
          Required: 'Enter your password.',
          MinLength: 'The password has to be at least 6 characters long',
        },
      },
    },
    TermsStep: {
      Header: 'You’re almost done!',
      Field: {
        Label: 'I agree to the  ',
        LinkText: 'Terms and conditions of {{appName}}',
        Errors: {
          Required: 'Accept the terms and conditions.',
        },
      },
    },
    ConfirmationSentStep: {
      Header: 'Conformation email sent',
      TextPart1: 'An email with the conformation link has been sent to ',
      TextPart2:
        '\n\nClick hte confirmation link in the email to complete your registration.\n\n If you do not receive a conformation email, please check your spam folder',
    },
  },
  RegisterConfirm: {
    ConfirmSuccess: 'Your account has been confirmed, you can now sign in.',
    Header: 'Confirm account.',
    GoToLogin: 'Back to sign in',
    ServerErrors: {
      Title: 'Account confirmation failed',
      DATA_NO_RESULT: 'Registration not found.',
      USER_NO_TEMPDATA: 'Registration not found.',
      USER_EMAIL_NOT_UNIQUE: 'An account with this email already exist.',
    },
  },
  ResetPassword: {
    Header: 'Reset password',
    Info: 'Enter your email address and you will receive an email with a link to reset your password.',
    Form: {
      Action: 'Reset',
      Fields: {
        Password: {
          Label: 'Password',
          Errors: {
            Required: 'Enter your new password.',
            MinLength: 'The password has to be at least 6 characters long.',
          },
        },
        ConfirmPassword: {
          Label: 'Confirm password',
          Errors: {
            Required: 'Enter your control password.',
            Equality: 'The control password does not match the password.',
          },
        },
      },
      ServerErrors: {
        Title: 'Failed to reset password',
        REQUESTIDENTIFIER_NOT_FOUND:
          'The request to change your password has not been found.',
        REQUESTIDENTIFIER_EXPIRED:
          'The request to change your password has expired.',
        REQUESTIDENTIFIER_REVOKED:
          'The request to change your password has been revoked.',
      },
      Succes:
        'Your password has been changed, you can sign in with your new password.',
    },
  },
  SavingCardsSummary: {
    Header: 'Saving card summary',
    NotCompleted: 'incomplete',
    Completed: 'Complete',
  },
  SendMoney: {
    Form: {
      Action: 'Send money',
      SearchAction: 'search',
      AuthenticateAction: '{{amount}} sent to {{name}}.',
      ConfirmMessage:
        'You wish to send {{amount}} to {{name}}. Want to proceed?',
      Success: {
        Title: 'Send money successful',
        Message: '{{amount}} has been sent to {{name}}.',
      },
      Fields: {
        Amount: {
          Label: 'Amount',
          Errors: {
            Required: 'Enter an amount.',
            MinMax: 'Enter an amount between {{min}} and {{max}}',
            // Min: 'Enter an amount of at least {{min}}',
            // Max: 'Enter an amount up to {{max}}',
          },
        },
        Description: {
          Label: 'Description (optional) ',
        },
        OtherWallet: {
          Label: 'Account',
          Error: 'Select an account.',
        },
        Search: {
          Label: 'Search (email address or activation code)',
          Errors: {
            Required: 'Enter an email address or activation code.',
          },
        },
      },
      ServerErrors: {
        Title: 'Failed to send money',
        CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED:
          'This payment method is not supported.',
        USER_NOT_FOUND: 'Account not found.',
        LOYALTYWALLET_SENDMONEY_SAME_USER:
          'You can send money to your own account.',
      },
    },
    Header: 'Send money',
    MaxBalance0: 'You can  ',
    MaxBalance1: ' sturen naar.',
    Tabs: {
      ChildWallet: 'Account',
      SearchWallet: 'Zoeken',
    },
  },
  SetDescriptionModal: {
    Form: {
      Action: 'Edit',
      DescriptionField: {
        Label: 'Description',
        Errors: {
          Required: 'Please enter a decription.',
        },
      },
      ServerErrors: {
        Title: 'Failed to edit description.',
      },
    },
    Title: 'Edit description',
  },
  Tokens: {
    Header: 'Cards',
    Activated: 'Activated',
    NoTokens: 'You don’t have cards yet.',
    ValidThru: 'Valid thru',
    TokenState: {
      Active: 'Active',
      Blocked: 'Blocked',
      Expired: 'Expired',
    },
    TokenMenu: {
      BlockTokenAction: {
        Title: 'Block card',
        Confirm:
          'Are you sure you wish to block this card ({{activationCode}})?',
        Succes: 'Card ({{activationCode}}) has been blocked',
      },
      UnblockTokenAction: {
        Title: '',
        Confirm:
          'Are you sure you want to unblock this card ({{activationCode}})?',
        Succes: 'Card ({{activationCode}}) has been unblocked',
      },
      SwitchUserAction: {
        Title: 'Change owner',
        Confirm:
          'Are you sure you want this card({{activationCode}}) assigned to {{name}}?',
        Succes: 'Card ({{activationCode}}) has been assigned to {{name}}',
      },
    },

    TokenBlockSuccess: 'Card has been blocked',
    TokenUnblockSuccess: 'Card has been unblocked',
  },
  UserCouponsSummary: {
    NotRedeemed: 'not redeemed',
    Redeemed: 'redeemed',
  },
  UserMenu: {
    Account: {
      Switch: 'Switch wallet',
      Add: 'Add wallet',
      AddSubText: 'Eg: for your kid.',
      Manage: 'Manage wallet',
      Delete: 'Delete wallet',
    },
    ResetPincode: 'Reset pin code',
    ResetPincodeConfirm: 'Are you sure you want to reset the pin code?',
    Logoff: 'Log out',
  },
  WalletDetails: {
    AutomaticCharge: 'Automatic Top up',
    Charge: 'Top up',
    NoTransactions: 'You have no transactions.',
    NoTransactionItems: 'No extra transaction data available. . ',
    ReportModal: {
      Header: 'Export the transactions for a period.',
      Fields: {
        FromDate: {
          Label: 'From date',
          Errors: {
            Required: 'Select the from date',
          },
        },
        TillDate: {
          Label: 'Till date',
          Errors: {
            Required: 'Select the till date',
            Equality: 'The till date must be greater than the from date.',
          },
        },
      },
    },
    SendMoney: 'Send money',
    Settings: 'Settings',
    Transactions: 'Transactions',
    Withdraw: 'Withdraw',
  },
  Withdraw: {
    Form: {
      Action: 'withdraw',
      AuthenticateAction: '{{amountWithoutCosts}} to withdraw.',
      ConfirmMessage:
        'You want to withdraw {{amount}}. ' +
        'The withdraw cost are {{transactionCosts}}.\n\n' +
        'This amount will be withdrawn{{amountWithoutCosts}}.\n\n' +
        'Want to proceed?',
      Success: {
        Title: 'Withdrawal successful',
        Message: 'The amount that will be withdrawn{{amountWithoutCosts}}.',
      },
      ChargeCostsMessage: 'The cost for withdrawal are ',
      AutomaticChargeCanceledMessage:
        'You have outstanding {{currencyName}}, you can’t withdraw at the moment.',
      Fields: {
        Amount: {
          Label: 'Amount',
          Errors: {
            Required: 'Enter amount.',
            MinMax: 'Enter an amount between {{min}} and {{max}}',
          },
        },
        Iban: {
          Label: 'Account number (IBAN)',
          Errors: {
            Required: 'Enter your Account number.',
          },
        },
        Name: {
          Label: 'Name account holder ',
          Errors: {
            Required: 'Enter your IBAN.',
          },
        },
      },
      ServerErrors: {
        Title: 'Withdrawal failed',
        CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED:
          'This payment method is not supported.',
      },
    },
    Header: 'Withdraw',
    MaxBalance0: 'You can withdraw ',
    MaxBalance1: ' max.',
  },
}
