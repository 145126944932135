export default {
  Account: {
    Header: 'Wallet',
    ChangeName: 'Naam wijzigen',
    ChangeEmail: 'E-mailadres wijzigen',
    ChangePassword: 'Wachtwoord wijzigen',
    SetProfilePicture: 'Profielfoto instellen',
    PictureModal: {TakePicture: 'Foto nemen', ChoosePicture: 'Foto kiezen'},
  },
  AutomaticCharge: {
    Buttons: {
      Create: 'Automatisch opwaarderen aanzetten',
      EditAmount: 'Bedrag aanpassen',
      EditBank: 'Bank gegevens aanpassen',
      TurnOff: 'Uitzetten',
    },
    Form: {
      Action: 'Aanzetten',
      Title: 'Automatisch opwaarderen voor {{currencyName}}',
      AuthenticateAction: 'Om automatisch opwaarderen aan te zetten.',
      ConfirmTurnOff:
        'Weet je zeker dat je automatisch opwaarderen wilt uitzetten voor {{currencyName}}?',
      CreateMessage: {
        Title: 'Automatisch opwaarderen aanzetten',
        Message:
          'Het kan enkele minuten duren voordat deze instelling is verwerkt.',
      },
      TurnOffMessage: {
        Title: 'Automatisch opwaarderen',
        Message: 'Het automatisch opwaarderen is uitgezet.',
      },
      EditMessage: {
        Title: 'Automatisch opwaarderen',
        Message: 'De wijzigingen zijn opgeslagen.',
      },
      ChargeCostsMessage: 'De kosten voor een automatische opwaardering zijn',
      Fields: {
        Amount: {
          Label: 'Opwaardeer bedrag',
        },
        WeeksAmount: {
          Label: 'Maximum aantal opwaarderingen per week',
        },
        Initials: {
          Label: 'Initialen',
        },
        Surname: {
          Label: 'Achternaam',
          Errors: {
            Required: 'Voer je achternaam in.',
          },
        },
        Email: {
          Label: 'E-mailadres',
          Errors: {
            Required: 'Voer je e-mailadres in.',
          },
        },
        Phone: {
          Label: 'Telefoonnummer',
          Errors: {
            Required: 'Voer je telefoonnummer in.',
          },
        },
        Street: {
          Label: 'Straatnaam',
          Errors: {
            Required: 'Voer je straatnaam in.',
          },
        },
        City: {
          Label: 'Plaatsnaam',
          Errors: {
            Required: 'Voer je plaatsnaam in.',
          },
        },
        StreetNumber: {
          Label: 'Huisnummer',
          Errors: {
            Required: 'Voer je huisnummer in.',
          },
        },
        Zipcode: {
          Label: 'Postcode',
          Errors: {
            Required: 'Voer je postcode in.',
          },
        },
        SelectedBank: {
          Label: 'Bank',
          EmptyText: '<Selecteer een bank>',
          Errors: {
            Required: 'Selecteer een bank.',
          },
        },
      },
      EMandateResults: {
        Title: 'Automatisch opwaarderen',
        EMANDATE_SUCCESS: 'Het Automatisch opwaarderen instellen is gelukt.',
        EMANDATE_CANCELLED:
          'Het Automatisch opwaarderen instellen is geannuleerd.',
        EMANDATE_EXPIRED:
          'Het Automatisch opwaarderen instellen aanvraag is verlopen (EMANDATE_EXPIRED).',
        EMANDATE_FAILURE:
          'Het Automatisch opwaarderen instellen is mislukt (EMANDATE_FAILURE).',
        EMANDATE_PENDING:
          "Het Automatisch opwaarderen instellen heeft de status 'In afwachting'.",
        EMANDATE_UNKNOWN_STATUS:
          'Er is een onbekende fout opgetreden tijdens het instellen van Automatisch Opwaarderen (EMANDATE_UNKNOWN_STATUS)',
      },
    },
    LoadingFailed: 'Ophalen portemonnee mislukt',
  },
  CancelAccount: {
    Confirm: 'Weet je zeker dat je dit account ({{name}}) wilt opheffen?',
    Header: 'Account opheffen',
    Info: 'Hier kun het account {{name}} opheffen. Het eventuele saldo wordt overgezet naar het hoofdaccount {{parentName}}.\n\nKaarten worden geblokkeerd en vervolgens gekoppeld aan de hoofdaccount {{parentName}}. Je kunt de kaarten weer gebruiken door ze te deblokkeren.',
    Succes: 'Account {{name}} is opgeheven',
  },
  AddAccountModal: {
    Form: {
      Action: 'Toevoegen',
      NameField: {
        Label: 'Naam',
        Errors: {
          Required: 'Voer een naam in.',
        },
      },
      ServerErrors: {
        Title: 'Account toevoegen mislukt',
      },
    },
  },
  AddToken: {
    Form: {
      Fields: {
        ActivationCode: {
          Label: 'Activatiecode',
          Errors: {
            Required: 'Voer je activatiecode in.',
          },
        },
      },
      ServerErrors: {
        Title: 'Kaart activeren mislukt',
        TOKEN_NOT_FOUND: 'Er is geen kaart gevonden met deze activatiecode.',
        TOKEN_ALREADY_ACTIVATED: 'Deze kaart is al geactiveerd.',
      },
      Succes:
        'De kaart met code {{activationCode}} is gekoppeld aan je account.',
    },
    Header: 'Kaart toevoegen',
    Info: 'Voer je activatiecode in',
  },
  AddTokenUid: {
    ScanAgain: 'Scan opnieuw',
    CardScanned: 'Kaart is gelezen.',
    Form: {
      Uid: {
        Label: 'Scan je kaart met de NFC lezer.',
        Errors: {
          Invalid: 'Dit is geen geldige kaart.',
          NotFound: 'Kaart niet gevonden',
        },
      },

      ServerErrors: {
        Title: 'Kaart activeren mislukt',
        TOKEN_NOT_FOUND: 'Er is geen kaart gevonden.',
        TOKEN_ALREADY_ACTIVATED: 'Deze kaart is al geactiveerd.',
      },
      Succes: 'De kaart is gekoppeld aan je account.',
    },
    Header: 'Kaart toevoegen',
  },
  AuthenticateAction: {
    Title: 'Authenticeren',
    Pin: 'Voer je pincode in om {{message}}',
    FingerPrint: 'Scan je vingerafdruk om {{message}}',
  },
  ChangeName: {
    Header: 'Naam wijzigen',
    Form: {
      Action: 'Naam wijzigen',
      NameField: {
        Label: 'Naam',
        Errors: {
          Required: 'Voer een naam in.',
        },
      },
      ServerErrors: {
        Title: 'Naam wijzigen mislukt',
      },
      Succes: 'Naam is gewijzigd.',
    },
  },
  ChangeEmail: {
    ConfirmationSentStep: {
      Header: 'Bevestigingsmail verstuurd',
      TextPart1:
        'Er is een e-mail verzonden met een confirmatielink naar het nieuwe e-mailadres ',
      TextPart2:
        '\n\nKlik op de confirmatielink in de e-mail om de verandering naar het nieuwe e-mailadres te voltooien.\n\nAls u geen bevestigingsmail ontvangt, controleer dan uw spam-map.',
    },
    Form: {
      Action: 'E-mailadres wijzigen',
      EmailField: {
        Label: 'E-mailadres',
        Errors: {
          Required: 'Voer een e-mailadres in.',
          Regex: 'Voer een geldig e-mailadres in.',
        },
      },
      ServerErrors: {
        Title: 'E-mailadres wijzigen mislukt',
        REQUESTIDENTIFIER_NOT_FOUND:
          'De aanvraag voor het wijzigen van je e-mailadres is niet gevonden.',
        REQUESTIDENTIFIER_EXPIRED:
          'De aanvraag voor het wijzigen van je e-mailadres is verlopen.',
        REQUESTIDENTIFIER_REVOKED:
          'De aanvraag voor het wijzigen van je e-mailadres is ingetrokken.',
        USER_EMAIL_NOT_UNIQUE: 'Er bestaat al een account met dit e-mailadres.',
      },
      Succes: 'E-mailadres is gewijzigd.',
    },
    Header: 'E-mailadres wijzigen',
  },
  ChangePassword: {
    Header: 'Wachtwoord wijzigen',
    Form: {
      Action: 'Wachtwoord wijzigen',
      Fields: {
        OldPassword: {
          Label: 'Oude wachtwoord',
          Errors: {
            Required: 'Voer je oude wachtwoord in.',
            Invalid: 'Het oude wachtwoord is onjuist.',
          },
        },
        Password: {
          Label: 'Wachtwoord',
          Errors: {
            Required: 'Voer je nieuwe wachtwoord in.',
            MinLength: 'Het wachtwoord moet minimaal 6 karakters lang zijn.',
          },
        },
        ConfirmPassword: {
          Label: 'Controle wachtwoord',
          Errors: {
            Required: 'Voer je controle wachtwoord in.',
            Equality:
              'Het controle wachtwoord is niet hetzelfde als het wachtwoord.',
          },
        },
      },
      ServerErrors: {
        Title: 'Wachtwoord wijzigen mislukt',
      },
      Succes: 'Wachtwoord is gewijzigd.',
    },
  },
  ChangeProfilePicture: {
    TakePictureHeader: 'Foto nemen',
    ChoosePictureHeader: 'Foto kiezen',
    Form: {
      Again: 'Opnieuw',
      Action: 'Opslaan',
    },
    Permissions: {
      Camera:
        'De app heeft toegang tot de camera nodig om een foto te kunnen nemen.',
      Library:
        "De app heeft toegang tot de foto's nodig om een foto te kunnen selecteren.",
    },
  },
  Charge: {
    Form: {
      Action: 'Opwaarderen',
      AuthenticateAction: '{{amount}} op te waarderen.',
      Success: {
        Title: 'Opwaardering gelukt',
        Message: 'Bijwerken portemonnee.',
      },
      ChargeCostsMessage: 'De kosten voor het opwaarderen bedragen ',
      Fields: {
        Amount: {
          Label: 'Bedrag',
          Errors: {
            Required: 'Voer het bedrag in.',
            //MinMax: 'Voer een bedrag in tussen {{min}} en {{max}}',
            Min: 'Voer een bedrag van minimaal {{min}}',
            Max: 'Voer een bedrag van maximaal {{max}}',
          },
        },
        AmountHb: {
          Label: 'Aantal munten',
          Errors: {
            Required: 'Selecteer het aantal munten.',
          },
        },
        ChargeMethod: {
          Label: 'Methode',
          Errors: {
            NotAvailable: 'Er zijn geen opwaardeermethodes beschikbaar.',
          },
        },
      },
      ServerErrors: {
        Title: 'Opwaarderen mislukt',
        CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED:
          'Deze betaalmethode wordt niet ondersteund.',
        //ideal errors
        PROCESS_FAILED: 'Proces mislukt.',
        PAYMENT_FAILED: 'Betaling mislukt.',
        PAYMENT_EXPIRED: 'Betaling verlopen.',
        PAYMENT_CANCELED: 'Betaling geannuleerd.',
        WAITING_FOR_PAYMENT: 'Afwachten op betaling.',
        UNKNOWN: 'Er is een onbekende fout opgetreden.',
        //PaymentOrderStatusTypes
        Refunded: 'Betaling teruggestort.',
        ProcessFailed: 'Process mislukt.',
        PaymentFailed: 'Betaling mislukt.',
        PaymentExpired: 'Betaling verlopen.',
        PaymentCanceled: 'Betaling geannuleerd.',
      },
    },
    Header: 'Opwaarderen',
    IntroText0: 'Kies een opwaardeermethode en voer het bedrag in waarmee je ',
    IntroText1: ' wilt opwaarderen.',
    MaxBalance0: 'Je kunt nog maximaal ',
    MaxBalance1: ' opwaarderen.',
    Method: 'Methode',
  },
  CodePush: {
    UpdateDialog: {
      Title: 'Update',
      OptionalUpdateMessage:
        'Er is een update beschikbaar. Wil je deze installeren?',
      OptionalInstallButtonLabel: 'Installeren',
      OptionalIgnoreButtonLabel: 'Negeren',
      MandatoryUpdateMessage:
        'Er is een belangrijke update beschikbaar, deze zal nu geïnstalleerd worden',
      MandatoryContinueButtonLabel: 'Verder',
    },
    UpdateTitle: 'Topr Wallet wordt bijgewerkt.',
    DownloadingPackage: 'De update wordt gedownload {{procent}}%.',
    InstallingUpdate: 'De update wordt geinstalleerd.',
  },
  Common: {
    Account: 'Wallet',
    Accounts: 'Wallets',
    Add: 'Toevoegen',
    Back: 'Terug',
    BackToDashboard: 'Terug naar Dashboard',
    Cancel: 'Annuleren',
    Close: 'Sluiten',
    Card: 'Kaart',
    Cards: 'Kaarten',
    Check: 'Controleren',
    Continue: 'Verder',
    Confirm: 'Bevestigen',
    Coupons: 'Vouchers',
    Date: 'Datum',
    Dashboard: 'Dashboard',
    DefaultErrorMessage: 'Helaas er is iets misgegaan. [{{code}}]',
    Delete: 'Verwijderen',
    Description: 'Omschrijving',
    Details: 'Details',
    DropImage: 'Klik hier om een foto te uploaden.',
    Edit: 'Wijzigen',
    End: 'Eind',
    Help: 'Help',
    Off: 'Uit',
    Ok: 'Ok',
    On: 'Aan',
    ToOrder: 'Bestellen',
    Order: 'Bestelling',
    Orders: 'Bestellingen',
    Pay: 'Betalen',
    QrCode: 'QR-code',
    Save: 'Opslaan',
    SavingCards: 'Spaarkaarten',
    Scanner: 'Scanner',
    Stamps: 'Stempels',
    Valid: 'Geldig',
    ValidAlways: 'Deze coupon is altijd geldig.',
    ValidFrom: 'Vanaf:',
    ValidUntill: 'Tot en met:',
    Location: 'Locatie',
    Menu: 'Menu',
    No: 'Nee',
    Received: 'Ontvangen',
    Redeem: 'Inleveren',
    Redeemed: 'Ingeleverd',
    Search: 'Zoeken',
    ServerErrors: {
      Title: 'Fout',
      NO_CONNECTION: 'Er is geen verbinding met het internet.',
      EXCEPTION: 'Helaas er is iets misgegaan.\n [{{code}}]',
    },
    Stamp: 'Stempelen',
    Start: 'Start',
    Status: 'Status',
    Summary: 'Overzicht',
    Token: 'Kaart',
    Tokens: 'Kaarten',
    Total: 'Totaal',
    Trips: 'Ritten',
    Wallet: 'Betaalmiddel',
    Wallets: 'Betaalmiddelen',
    Yes: 'Ja',
  },
  CouponsSummary: {
    Header: 'Coupons overzicht',
    NotRedeemed: 'Niet ingeleverd',
    Redeemed: 'Ingeleverd',
  },
  Dashboard: {
    AddToken: 'Kaart toevoegen',
    AddLocation: 'Locatie toevoegen',
    Tabs: {
      Payment: 'BETALEN',
      Coupons: 'COUPONS',
      Savings: 'SPAREN',
      Subscriptions: 'Abbonnementen',
      Tickets: 'Tickets',
    },
    NoCoupons: 'Je hebt nog geen coupons.',
    NoSavingCards: 'Je hebt nog geen spaarkaarten.',
  },
  Enums: {
    AutomaticChargeStatusTypesDTO: {
      Off: 'Uitgeschakeld',
      Pending: 'Aanvraag wachtend op verificatie',
      Denied: 'Aanvraag afgewezen',
      Blocked: 'Geblokkeerd',
      EnabledWithVerification: 'Actief',
      EnabledWithoutVerification: 'Actief',
      Initial: 'Niet ingesteld',
    },
  },
  GeneralMenu: {
    CouponSummary: 'Coupon overzicht',
    SavingCardSummary: 'spaarkaarten overzicht',
  },
  ForgotPassword: {
    Header: 'Wachtwoord resetten',
    Info: 'Voer je e-mailadres in en we sturen je een link om je wachtwoord te resetten.',
    Form: {
      Action: 'E-mail versturen',
      EmailField: {
        Label: 'E-mailadres',
        Errors: {
          Required: 'Voer je e-mailadres in.',
        },
      },
      ServerErrors: {
        Title: 'Wachtwoord resetten mislukt',
      },
      Succes: {
        Message:
          'Er is een e-mail verstuurd naar {{email}} met daarin een link om je wachtwoord opnieuw in te stellen.',
        Title: 'E-mail verstuurd',
      },
    },
  },
  Locations: {
    Header: 'Locaties',
    NoLocations: 'Er zijn nog geen {{tenantName}} Bij je in de buurt.',
    RequestAccess: {
      AllowLocationPermission: 'Locatie toegang toestaan',
      Title: 'Locatie toegang',
      Message:
        'Topr Wallet heeft toegang tot je locatie nodig om te bepalen welke locaties er bij jou in de buurt zijn waar je Topr Wallet kunt gebruiken.',
      OkButton: 'Toestaan',
      NoButton: 'Weigeren',
      SettingsMessageAndroid:
        'Druk op de knop "Locatie toegang toestaan" > Machtigingen > Locatie.',
      SettingsMessageIos:
        'Druk op de knop "Locatie toegang toestaan" > Privacy > Locatievoorzieningen > Topr Wallet > \'Bij gebruik app\'.',
    },
  },
  LocationDetails: {
    Tabs: {
      Payment: 'BETALEN',
      Location: 'Locatie',
    },
    AddLocation: {
      Action: 'Locatie toevoegen',
      Confirm: 'Weet je zeker dat je deze locatie wilt toevoegen?',
      Success: 'Locatie {{locationName}} toegevoegd aan je portemonnee.',
    },
    Address: 'Adres',
    City: 'Plaats',
    Route: 'Route',
    Street: 'Straat',
  },
  Login: {
    CreateAccount: 'Account aanmaken',
    ForgotPassword: 'Wachtwoord vergeten?',
    Form: {
      Action: 'Inloggen',
      Fields: {
        Email: {
          Label: 'E-mailadres',
          Errors: {
            Required: 'Voer je e-mailadres in.',
          },
        },
        Password: {
          Label: 'Wachtwoord',
          Errors: {
            Required: 'Voer je wachtwoord in.',
          },
        },
      },
      Header: 'Inloggen',
      ServerErrors: {
        Title: 'Inloggen mislukt',
        USER_PASSWORD_INVALID: 'Ongeldige email/wachtwoord combinatie.',
        USER_LOCKED_SHORT:
          'Je account is tijdelijk geblokkeerd. Probeer het opnieuw na 15 minuten.',
        USER_LOCKED_LONG:
          'Je account is tijdelijk geblokkeerd. Probeer het opnieuw na 1 uur.',
        USER_BLOCKED:
          'Je account is geblokeert. Neem contact op met de helpdesk om je account te de-blokkeren.',
        USER_NOT_FOUND:
          'Er geen account gevonden voor deze email/wachtwoord combinatie.',
      },
    },
    Header: 'Inloggen',
    LoginExpired: 'Je login is verlopen, log opnieuw in.',
  },
  Loyalty: {
    Status: {
      Active: 'Actief',
      Expired: 'Verlopen',
      NotActive: 'Niet actief',
      Redeemed: 'Ingeleverd',
      Completed: 'Compleet',
    },
  },
  OrderOnline: {
    Add: 'Toevoegen',
    Cart: 'Winkelmandje',
    Pay: 'Betalen',
    Checkout: 'Afrekenen',
    CondimentArticleGroup: 'Extra',
    NoArticles: 'Er zijn nog geen producten.',
    Max: 'Max: {{max}}',
    Min: 'Min: {{min}} ',
    MaxErrorMessage: 'Je kunt niet meer dan {{max}} {{type}} selecteren.',
    MinErrorMessage: 'Selecteer minimaal {{min}} {{type}} .',
    OptionalChoice: 'Optionele keuze - ',
    PaymentMethods: {
      PayOnDelivery: 'Betalen bij bezorging',
      ToprWallet: 'Betaal met je Topr Wallet',
    },
    PaymentForm: {
      AuthenticateAction: '{{amount}} te betalen.',
      Fields: {
        SelectedCurrency: {
          Label: 'Betaalmiddel',
          Errors: {
            NotAvailable: 'Er zijn geen betaalmiddelen beschikbaar.',
          },
        },
      },
      InsufficientBalance: 'Je hebt onvoldoende saldo.',
      PaymentOrderDescriptionTable: 'Bestelling tafel {{tableNumber}}',
      ServerErrors: {
        Title: 'Bestellen mislukt',
        UNKNOWN: 'Er is een onbekende fout opgetreden.',
        INVALID_DELIVERY_TYPE: 'Onbekende aflevermethode ({{deliveryType}})',
        INVALID_GATEWAY: 'Ongeldige betaalwijze ({{gateway}})',
      },
      Success: {
        Title: 'bestelling geplaatst',
        Message:
          'De betaling is succesvol afgerond en je bestelling met bestelnummer ({{orderNumber}}) is geplaatst.',
      },
    },
    RequiredChoice: 'Verplichte keuze',
    SearchProduct: 'Zoek naar een product',
    CheckoutForm: {
      Success: {
        Title: 'Bestelling geplaatst',
        Message:
          'Je bestelling met bestelnummer ({{orderNumber}}) is geplaatst.',
      },
      Fields: {
        Remarks: {
          Label: 'Opmerkingen (optioneel)',
        },
        PaymentMethod: {
          Label: 'Betaalwijze',
          Errors: {
            NotAvailable: 'Er zijn geen betaalwijzen beschikbaar.',
          },
        },
      },
      ServerErrors: {
        Title: 'Bestellen mislukt',
        UNKNOWN: 'Er is een onbekende fout opgetreden.',
        INVALID_DELIVERY_TYPE: 'Onbekende aflevermethode ({{deliveryType}})',
        INVALID_GATEWAY: 'Ongeldige betaalwijze ({{fateway}})',
      },
    },
  },
  Pincode: {
    Form: {
      Action: 'Pincode instellen',
      Fields: {
        Pincode: {
          Label: 'Pincode',
          Errors: {
            Required: 'Voer een pincode in.',
            MinLength: 'De pincode moet 4 nummers langs zijn.',
            Regex:
              'De pincode moet 4 nummers langs zijn en mag alleen uit nummers bestaan.',
          },
        },
        ConfirmPincode: {
          Label: 'Bevestig pincode',
          Errors: {
            Required: 'Bevestig je pincode.',
            MinLength: 'De pincode moet 4 nummers langs zijn.',
            Regex:
              'De pincode moet 4 nummers langs zijn en mag alleen uit nummers bestaan.',
            Equality: 'De bevestig pincode moet gelijk zijn aan de pincode.',
          },
        },
      },
      Header: 'Pincode instellen',
    },
    InvalidMessage: 'De pincode is onjuist',
  },
  PlacedOrders: {
    Header: 'Bestellingen',
    NoOrders: 'Je hebt nog geen bestellingen.',

    Table: 'Tafel',
  },
  PlacedOrderDetails: {
    Receipt: 'Kassabon',
    Date: 'Datum:',
    OrderNumber: 'Ordernummer:',
    OrderId: 'Order Id:',
  },
  QrCode: {
    Error: {
      Title: 'QR-code fout',
      InvalidClientDate:
        'Ongeldige QR-code, controlleer de datum/tijd op je telefoon.',
    },
  },
  QrCodeScanner: {
    BleFailed:
      '(BLE_FAILED)\nWe konden niet bepalen of je in de buurt bent, probeer het over 10 seconden nog eens.',
    BranchOfficeNotFound:
      'Geen locatie gevonden voor deze QR-code ({{vsamGuid}})',
    CameraPermission:
      'De app heeft toegang tot de camera nodig om een QR-code te kunnen scannen.',
    NoBleNearby: 'Helaas je bent niet in debuurt van een Locatie.',
    InvalidQrCode: 'Ongeldige QR-code',
    ScanAgain: 'Opnieuw scannen',
    ScanningLocation: 'Locatie controle...',
  },
  Register: {
    Header: 'Account aanmaken',
    Form: {
      Action: 'Account aanmaken',
      ServerErrors: {
        Title: 'Account aanmaken mislukt',
        USER_EMAIL_NOT_UNIQUE: 'Er bestaat al een account met dit e-mailadres.',
      },
    },
    NameStep: {
      Header: 'Hallo! Wat is je naam?',
      Info: 'Vul je volledige naam in.',
      Field: {
        Label: 'Naam',
        Errors: {
          Required: 'Voer je naam in.',
        },
      },
    },
    EmailStep: {
      Header: 'Leuk je te ontmoeten {{name}}!',
      Info: 'Wat is je e-mailadres?',
      Field: {
        Label: 'E-mailadres',
        Errors: {
          Required: 'Voer je e-mailadres in.',
          Regex: 'Voer een geldig e-mailadres in.',
        },
      },
    },
    PasswordStep: {
      Header: 'Kies een wachtwoord',
      Info: 'Het wachtwoord moet minimaal 6 karakters lang zijn.',
      Field: {
        Label: 'Wachtwoord',
        Errors: {
          Required: 'Voer je wachtwoord in.',
          MinLength: 'Het wachtwoord moet minimaal 6 karakters lang zijn.',
        },
      },
    },
    TermsStep: {
      Header: 'Je bent bijna klaar!',
      Field: {
        Label: 'Ik ga akkoord met de ',
        LinkText: 'Algemene Voorwaarden van {{appName}}',
        Errors: {
          Required: 'Accepteer de Algemene Voorwaarden.',
        },
      },
    },
    ConfirmationSentStep: {
      Header: 'Bevestigingsmail verstuurd',
      TextPart1: 'Er is een e-mail verzonden met een confirmatielink naar ',
      TextPart2:
        '\n\nKlik op de confirmatielink in de e-mail om de registratie te voltooien.\n\nAls u geen bevestigingsmail ontvangt, controleer dan uw spam-map.',
    },
  },
  RegisterConfirm: {
    ConfirmSuccess: 'Je account is bevestigd, je kunt nu inloggen.',
    Header: 'Account bevestigen.',
    GoToLogin: 'Terug naar inloggen',
    ServerErrors: {
      Title: 'Bevestigen account mislukt',
      DATA_NO_RESULT: 'Registratie niet gevonden.',
      USER_NO_TEMPDATA: 'Registratie niet gevonden.',
      USER_EMAIL_NOT_UNIQUE: 'Er bestaat al een account met dit e-mailadres.',
    },
  },
  ResetPassword: {
    Header: 'Wachtwoord resetten',
    Info: 'Voer je e-mailadres in en we sturen je een link om je wachtwoord te resetten.',
    Form: {
      Action: 'Wijzigen',
      Fields: {
        Password: {
          Label: 'Wachtwoord',
          Errors: {
            Required: 'Voer je nieuwe wachtwoord in.',
            MinLength: 'Het wachtwoord moet minimaal 6 karakters lang zijn.',
          },
        },
        ConfirmPassword: {
          Label: 'Controle wachtwoord',
          Errors: {
            Required: 'Voer je controle wachtwoord in.',
            Equality:
              'Het controle wachtwoord is niet hetzelfde als het wachtwoord.',
          },
        },
      },
      ServerErrors: {
        Title: 'Wachtwoord resetten mislukt',
        REQUESTIDENTIFIER_NOT_FOUND:
          'De aanvraag voor het wijzigen van je wachtwoord is niet gevonden.',
        REQUESTIDENTIFIER_EXPIRED:
          'De aanvraag voor het wijzigen van je wachtwoord is verlopen.',
        REQUESTIDENTIFIER_REVOKED:
          'De aanvraag voor het wijzigen van je wachtwoord is ingetrokken.',
      },
      Succes:
        'Je wachtwoord is gewijzigd, je kunt nu inloggen met je nieuwe wachtwoord.',
    },
  },
  SavingCardsSummary: {
    Header: 'Spaarkaarten overzicht',
    NotCompleted: 'Niet compleet',
    Completed: 'Compleet',
  },
  SendMoney: {
    Form: {
      Action: 'Geld sturen',
      SearchAction: 'Zoeken',
      AuthenticateAction: '{{amount}} naar {{name}} te sturen.',
      ConfirmMessage:
        'Je wilt {{amount}} sturen naar {{name}}. Wil je doorgaan?',
      Success: {
        Title: 'Geld sturen gelukt',
        Message: 'Er is {{amount}} gestuurd naar {{name}}.',
      },
      Fields: {
        Amount: {
          Label: 'Bedrag',
          Errors: {
            Required: 'Voer het bedrag in.',
            MinMax: 'Voer een bedrag in tussen {{min}} en {{max}}',
            // Min: 'Voer een bedrag van minimaal {{min}}',
            // Max: 'Voer een bedrag van maximaal {{max}}',
          },
        },
        Description: {
          Label: 'Omschrijving (optioneel) ',
        },
        OtherWallet: {
          Label: 'Account',
          Error: 'Selecteer een account.',
        },
        Search: {
          Label: 'Zoeken (e-mailadres, activatiecode)',
          Errors: {
            Required: 'Voer een e-mailadres of activatiecode in.',
          },
        },
      },
      ServerErrors: {
        Title: 'Geld sturen mislukt',
        CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED:
          'Deze betaalmethode wordt niet ondersteund.',
        USER_NOT_FOUND: 'Geen account gevonden.',
        LOYALTYWALLET_SENDMONEY_SAME_USER:
          'Je kunt geen geld naar jezelf sturen.',
      },
    },
    Header: 'Geld sturen',
    MaxBalance0: 'Je kunt maximaal ',
    MaxBalance1: ' sturen naar een ander account.',
    Tabs: {
      ChildWallet: 'Account',
      SearchWallet: 'Zoeken',
    },
  },
  SetDescriptionModal: {
    Form: {
      Action: 'Wijzigen',
      DescriptionField: {
        Label: 'Omschrijving',
        Errors: {
          Required: 'Voer een omschrijving in.',
        },
      },
      ServerErrors: {
        Title: 'Wijzigen omshcrijving mislukt.',
      },
    },
    Title: 'Omschrijving wijzigen',
  },
  Tokens: {
    Header: 'Kaarten',
    Activated: 'Geactiveerd',
    NoTokens: 'Je hebt nog geen kaarten.',
    ValidThru: 'Geldig tot',
    TokenState: {
      Active: 'Actief',
      Blocked: 'Geblokkeerd',
      Expired: 'Verlopen',
    },
    TokenMenu: {
      BlockTokenAction: {
        Title: 'Blokkeer kaart',
        Confirm:
          'Weet je zeker dat je deze kaart ({{activationCode}}) wilt blokkeren?',
        Succes: 'Kaart ({{activationCode}}) is geblokkeerd',
      },
      UnblockTokenAction: {
        Title: 'Deblokkeer kaart',
        Confirm:
          'Weet je zeker dat je deze kaart ({{activationCode}}) wilt deblokkeren?',
        Succes: 'Kaart ({{activationCode}}) is gedeblokkeerd',
      },
      SwitchUserAction: {
        Title: 'Eigenaar veranderen',
        Confirm:
          'Weet je zeker dat je deze kaart ({{activationCode}}) wilt toewijzen aan {{name}}?',
        Succes: 'Kaart ({{activationCode}}) is toegewezen aan {{name}}',
      },
    },

    TokenBlockSuccess: 'Kaart is geblokkeerd',
    TokenUnblockSuccess: 'Kaart is gedeblokkeerd',
  },
  UserCouponsSummary: {
    NotRedeemed: 'Niet ingeleverd',
    Redeemed: 'Ingeleverd',
  },
  UserMenu: {
    Account: {
      Switch: 'Wallet wisselen',
      Add: 'Wallet toevoegen',
      AddSubText: 'Bijvoorbeeld voor uw kind.',
      Manage: 'Wallet beheren',
      Delete: 'Wallet verwijderen',
    },
    ResetPincode: 'Reset pincode',
    ResetPincodeConfirm: 'Weet je zeker dat je de pincode wilt resetten?',
    Logoff: 'Afmelden',
  },
  WalletDetails: {
    AutomaticCharge: 'Automatisch Opwaarderen',
    Charge: 'Opwaarderen',
    NoTransactions: 'Je hebt nog geen transacties.',
    NoTransactionItems: 'Er is geen extra transactie data.',
    ReportModal: {
      Header: 'Exporteer de transacties in een periode.',
      Fields: {
        FromDate: {
          Label: 'Begindatum',
          Errors: {
            Required: 'Selecteer de begindatum',
          },
        },
        TillDate: {
          Label: 'Tot en met',
          Errors: {
            Required: 'Selecteer de tot en met datum',
            Equality: 'De tot en met datum moet groter zijn dan de begindatum',
          },
        },
      },
    },
    SendMoney: 'Geld sturen',
    Settings: 'Instellingen',
    Withdraw: 'Terugboeken',
    Transactions: 'Transacties',
  },
  Withdraw: {
    Form: {
      Action: 'Terugboeken',
      AuthenticateAction: '{{amountWithoutCosts}} terug te boeken.',
      ConfirmMessage:
        'Je wilt {{amount}} terugboeken. ' +
        'De kosten voor het terugboeken bedragen {{transactionCosts}}.\n\n' +
        'Er zal {{amountWithoutCosts}} teruggeboekt worden.\n\n' +
        'Wil je doorgaan?',
      Success: {
        Title: 'Terugboeken gelukt',
        Message: 'Er zal {{amountWithoutCosts}} teruggestort worden.',
      },
      ChargeCostsMessage: 'De kosten voor het terugboeken bedragen ',
      AutomaticChargeCanceledMessage:
        'Je hebt nog openstaande storneringen voor {{currencyName}}, daarom kun je op dit moment geen geld terugboeken.',
      Fields: {
        Amount: {
          Label: 'Bedrag',
          Errors: {
            Required: 'Voer het bedrag in.',
            MinMax: 'Voer een bedrag in tussen {{min}} en {{max}}',
          },
        },
        Iban: {
          Label: 'Rekeningnummer (IBAN)',
          Errors: {
            Required: 'Voer je rekeningnummer in.',
          },
        },
        Name: {
          Label: 'Naam rekeninghouder ',
          Errors: {
            Required: 'Voer je IBAN in.',
          },
        },
      },
      ServerErrors: {
        Title: 'Terugboeken mislukt',
        CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED:
          'Deze betaalmethode wordt niet ondersteund.',
      },
    },
    Header: 'Terugboeken',
    MaxBalance0: 'Je kunt maximaal ',
    MaxBalance1: ' terugboeken.',
  },
}
