import {IServiceConfig} from '../../Models/Client'
import {LogLevel} from '../../Models/Client/Enums'

const ProductionConfig: IServiceConfig = {
  ToprAuthUrl: 'https://auth.topr.services/',
  BaseUrl: 'https://core.topr.services/',
  CmsUrl: 'https://cms.toprwallet.nl',
  ClientId: '797e1a1a-70bf-4ca9-9320-5e30ae98704c', //toprclientid:6 toprConsumerapp
  Secret: '',
  ShowLogging: false,
  LogLevel: LogLevel.Error,
}

export default ProductionConfig
