import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Animated} from 'react-native'
import {SceneMap, TabBar, TabView} from 'react-native-tab-view'
import i18n from '../../../../i18n'
import Style, {tabViewLayout} from '../../../../Style'
import {colors} from '../../../../Style/index'
import {dashboardStore} from '../../Dashboard/Store'
import {dashboardHeaderStore} from '../DashboardHeader/Store'
import CouponsTab from './CouponsTab'
import SavingCardsTab from './SavingCardsTab'
import SubscriptionsTab from './SubscriptionsTab'
import TicketsTab from './TicketsTab'
import WalletsTab from './WalletsTab'
interface IProps {}

const TABBAR_MAX_HEIGHT = 110 // max header height
const TABBAR_MIN_HEIGHT = 55 // min header height
const TABBAR_SCROLL_DISTANCE = TABBAR_MAX_HEIGHT - TABBAR_MIN_HEIGHT

const DashboardTabs: React.FC<IProps> = ({children}) => {
  const [index, setIndex] = React.useState(0)

  const {UserSubscriptions, UserSavingCards, UserEvents} = dashboardStore

  const setIndexAndResetScroll = (value: number) => {
    setIndex(value)
    dashboardHeaderStore.ResetScrollYAnimated()
  }

  const initialTabs = [
    {key: 'wallets', title: i18n.t('Dashboard.Tabs.Payment')},
    {key: 'coupons', title: i18n.t('Dashboard.Tabs.Coupons')},
  ]
  const [tabs, setTabs] = React.useState(initialTabs)
  useEffect(() => {
    const dynamicTabs = []
    if (UserSubscriptions.length > 0) {
      dynamicTabs.push({
        key: 'userSubscriptions',
        title: i18n.t('Dashboard.Tabs.Subscriptions'),
      })
    }
    if (UserSavingCards.length > 0) {
      dynamicTabs.push({
        key: 'usersavingsCard',
        title: i18n.t('Dashboard.Tabs.Savings'),
      })
    }
    if (UserEvents.length > 0) {
      dynamicTabs.push({
        key: 'tickets',
        title: i18n.t('Dashboard.Tabs.Tickets'),
      })
    }

    setTabs([...initialTabs, ...dynamicTabs])
  }, [UserSubscriptions, UserSavingCards, UserEvents])

  const {ScrollY} = dashboardHeaderStore
  const {tabBarLabelStyle} = Style
  const headerTranslateY = ScrollY.interpolate({
    inputRange: [0, TABBAR_MIN_HEIGHT],
    outputRange: [0, -TABBAR_MIN_HEIGHT],
    extrapolate: 'clamp',
  })

  let map = {
    wallets: () => <WalletsTab />,
    coupons: () => <CouponsTab />,
    usersavingsCard: () =>
      UserSavingCards.length > 0 ? <SavingCardsTab /> : <></>,
    userSubscriptions: () =>
      UserSubscriptions.length > 0 ? <SubscriptionsTab /> : <></>,
    tickets: () => (UserEvents.length > 0 ? <TicketsTab /> : <></>),
  }

  const renderScene = SceneMap(map)

  const renderTabBar = (props: any) => (
    <Animated.View
      style={[
        {
          position: 'absolute',
          top: TABBAR_MIN_HEIGHT,
          left: 0,
          right: 0,
          transform: [{translateY: headerTranslateY}],
        },
      ]}>
      <TabBar
        {...props}
        indicatorStyle={{backgroundColor: colors.main}}
        style={[{backgroundColor: 'white'}]}
        labelStyle={tabBarLabelStyle}
        activeColor={colors.main}
        inactiveColor={colors.darkGrey}
        scrollEnabled={true}
      />
    </Animated.View>
  )

  return (
    <TabView
      navigationState={{index, routes: tabs}}
      renderScene={renderScene}
      onIndexChange={setIndexAndResetScroll}
      initialLayout={tabViewLayout}
      renderTabBar={renderTabBar}
      tabBarPosition="bottom"
    />
  )
}

export default observer(DashboardTabs)
